export const TrmTracking = {
	// headers
	learn_more: 'header|link.clicked|learn_more',
    pricing: 'header|link.clicked|pricing',
    contact_us: 'header|link.clicked|contact_us',
    phone_number_header: 'header|link.clicked|phone_number',

	// homepage
    input_tm_click: 'homepage|field.clicked|input_tm',
    input_tm_input: 'homepage|field.input|input_tm',
    check_tm_availability: 'homepage|button.clicked|check_tm_availability',
    tm_logo: 'homepage|link.clicked|tm_logo',
    tm_name: 'homepage|link.clicked|tm_name',
    start_now: 'homepage|button.clicked|start_now',

    // footer
    about_us: 'footer|link.clicked|about_us',
    home_contact_us: 'footer|link.clicked|contact_us',
    home_pricing: 'footer|link.clicked|pricing',
    terms_of_use: 'footer|link.clicked|terms_of_use',
    privacy_policy: 'footer|link.clicked|privacy_policy',
    bn_rego: 'footer|link.clicked|bn_rego',
    bn_renewal: 'footer|link.clicked|bn_renewal',
    bn_transfer: 'footer|link.clicked|bn_transfer',
    comp_rego: 'footer|link.clicked|comp_rego',
    abn_registration: 'footer|link.clicked|abn_registration',
    tfn_rego: 'footer|link.clicked|tfn_rego',
    dn: 'footer|link.clicked|dn',
    email: 'footer|link.clicked|email',
    website: 'footer|link.clicked|website',
    google_listing: 'footer|link.clicked|google_listing',

    //registration

	    // your trademark use
	    tm_for_clicked: 'registration|field.clicked|tm_for',
		tm_for_input: 'registration|field.input|tm_for',
		tm_for_invalid: 'registration|element.invalid|tm_for',
		tm_remove: 'registration|button.clicked|tm_remove',
		tm_classification: 'registration|button.hover|tm_classification',
		expert_draft: 'registration|checkbox.clicked|expert_draft',
		tm_usage: 'registration|button.hover|tm_usage',

		// privacy protection
		privacy_yes: 'registration|button.clicked|privacy_yes',
		privacy_no: 'registration|button.clicked|privacy_no',

		// your details
		business: 'registration|button.clicked|business',
		individual: 'registration|button.clicked|individual',
		abn_acn: 'registration|field.clicked|abn_acn',
		abn_acn_input: 'registration|field.input|abn_acn',
		abn_acn_invalid: 'registration|element.invalid|abn_acn',
		abn_acn_tooltip_hover: 'registration|button.hover|abn_acn_tooltip',
		abn_acn_tooltip_clicked: 'registration|button.clicked|abn_acn_tooltip',	
		abn_acn_here: 'registration|link.clicked|abn_acn_here',
		abn_acn_not_valid: 'registration|link.clicked|abn_acn_not_valid',
		abn_not_valid: 'registration|link.clicked|abn_not_valid',
		abn_not_active: 'registration|link.clicked|abn_inactive',
		individual_no: 'registration|button.clicked|individual_no',
		individual_yes: 'registration|button.clicked|individual_yes',
		given_name_clicked: 'registration|field.clicked|given_name',
		given_name_input: 'registration|field.input|given_name',
		given_name_invalid: 'registration|element.invalid|given_name',
		last_name_clicked: 'registration|field.clicked|last_name',
		last_name_input: 'registration|field.input|last_name',
		last_name_invalid: 'registration|element.invalid|last_name',
		ind_abn_clicked: 'registration|field.clicked|ind_abn',
		ind_abn_input: 'registration|field.input|ind_abn',
		ind_abn_invalid: 'registration|element.invalid|ind_abn',
		abn_registration_tooltip_hover: 'registration|button.hover|abn_registration_tooltip',
		abn_registration_tooltip_clicked: 'registration|button.clicked|abn_registration_tooltip',
		abn_here_clicked: 'registration|link.clicked|abn_here',

		email_address_clicked: 'registration|field.clicked|email_address',
		email_address_invalid: 'registration|element.invalid|email_address',
		phone_number_clicked: 'registration|field.clicked|phone_number',
		phone_number_invalid: 'registration|element.invalid|phone_number',
		auto_address_clicked: 'registration|field.clicked|auto_address',
		auto_address_input: 'registration|field.input|auto_address',
		auto_address_invalid: 'registration|element.invalid|auto_address',
		auto_to_manual_address: 'registration|link.clicked|auto_to_manual_address',
		street_no_clicked: 'registration|field.clicked|street_no',
		street_no_input: 'registration|field.input|street_no',
		street_no_invalid: 'registration|element.invalid|street_no',
		street_name_clicked: 'registration|field.clicked|street_name',
		street_name_input: 'registration|field.input|street_name',
		street_name_invalid: 'registration|element.invalid|street_name',
		city_clicked: 'registration|field.clicked|city',
		city_input: 'registration|field.input|city',
		city_invalid: 'registration|element.invalid|city',
		state_clicked: 'registration|field.clicked|state',
		state_invalid: 'registration|element.invalid|state',
		post_clicked: 'registration|field.clicked|post',
		post_input: 'registration|field.input|post',
		post_invalid: 'registration|element.invalid|post',
		manual_to_auto_address: 'registration|link.clicked|manual_to_auto_address',

		// ASIC business name registration
		bn_years: {
			'yes-3': 'registration|button.clicked|bn_3_years',
			'yes-1': 'registration|button.clicked|bn_1_year',
			'no': 'registration|button.clicked|bn_no',
		},
		country_of_birth: 'registration|field.clicked|country_of_birth',
		state_of_birth: 'registration|field.clicked|state_of_birth',
		city_of_birth_clicked: 'registration|field.clicked|city_of_birth',
		city_of_birth_input: 'registration|field.input|city_of_birth',
		city_of_birth_invalid: 'registration|element.invalid|city_of_birth',
		asic_agent_tooltip_hover: 'registration|button.hover|asic_agent_tooltip',
		asic_agent_tooltip_clicked: 'registration|button.clicked|asic_agent_tooltip',

		// domain name registration
		dn_years: {
			'yes': 'registration|button.clicked|dn_2_years',
			'no': 'registration|button.clicked|dn_new',
			'no-reg': 'registration|button.clicked|dn_no',
		},
		
		domain_name_tooltip_hover: 'registration|button.hover|domain_name_tooltip',
		domain_name_tooltip_clicked: 'registration|button.clicked|domain_name_tooltip',
		
		dn_different_clicked:'registration|field.clicked|dn_different',
		dn_different_input:'registration|field.input|dn_different',
		dn_different_invalid:'registration|element.invalid|dn_different',
		tld:'registration|field.clicked|tld',
		terms:'registration|field.clicked|terms',

		dn_search: 'registration|button.clicked|dn_search',
		dn_clear: 'registration|button.clicked|dn_clear',

		terms_agree_clicked: 'registration|checkbox.clicked|terms_agree',
		terms_agree_invalid: 'registration|element.invalid|terms_agree',
		terms_of_service: 'registration|link.clicked|terms_of_service',
		continue: 'registration|button.clicked|continue',

	// payment
	icon_mastercard: 'payment|button.clicked|icon_mastercard',
	icon_visa: 'payment|button.clicked|icon_visa',
	icon_amex: 'payment|button.clicked|icon_amex',
	icon_bpay: 'payment|button.clicked|icon_bpay',
	cc_given_name_clicked: 'payment|field.clicked|cc_given_name',
    cc_given_name_input: 'payment|field.input|cc_given_name',
	cc_last_name_clicked: 'payment|field.clicked|cc_last_name',
    cc_last_name_input: 'payment|field.input|cc_last_name',
	street_no_name_clicked: 'payment|field.clicked|street_no_name',
    street_no_name_input: 'payment|field.input|street_no_name',
    street_no_name_invalid: 'payment|element.invalid|street_no_name',
	payment_city_clicked: 'payment|field.clicked|city',
    payment_city_input: 'payment|field.input|city',
    payment_city_invalid: 'payment|element.invalid|city',
	payment_state_clicked: 'payment|field.clicked|state',
    payment_state_input: 'payment|field.input|state',
    payment_state_invalid: 'payment|element.invalid|state',
	postcode_clicked: 'payment|field.clicked|postcode',
    postcode_input: 'payment|field.input|postcode',
    postcode_invalid: 'payment|element.invalid|postcode',
	country_clicked: 'payment|field.clicked|country',
    country_invalid: 'payment|element.invalid|country',
	email_receipt_clicked: 'payment|field.clicked|email_receipt',
    email_receipt_input: 'payment|field.input|email_receipt',
    email_receipt_invalid: 'payment|element.invalid|email_receipt',
	email_receipt_to_t_hover: 'payment|button.hover|email_receipt_to_t',
    email_receipt_to_t_clicked: 'payment|button.clicked|email_receipt_to_tooltip',
	complete_payment: 'payment|button.clicked|complete_payment',
	eway_payment: 'payment|button.clicked|eway',
};
