export const TfnTracking = {
  passport_number: 'application_page|field.clicked|passport_number',
  passport_country: 'application_page|field.clicked|passport_country',
  title: 'application_page|field.clicked|title',
  given_name: 'application_page|field.clicked|given_name',
  last_name: 'application_page|field.clicked|last_name',
  previous_name_tick: 'application_page|field.clicked|previous_name_tick',
  previous_name: 'application_page|field.clicked|previous_name',
  gender: 'application_page|field.clicked|gender',
  day_dob: 'application_page|field.clicked|day_dob',
  month_dob: 'application_page|field.clicked|month_dob',
  year_dob: 'application_page|field.clicked|year_dob',
  have_spouse_no: 'application_page|field.clicked|have_spouse_no',
  have_spouse_yes: 'application_page|field.clicked|have_spouse_yes',
  spouse_family_name: 'application_page|field.clicked|spouse_family_name',
  spouse_given_name: 'application_page|field.clicked|spouse_given_name',
  spouse_other_given_name: 'application_page|field.clicked|spouse_other_given_name',
  travel_document: 'application_page|field.clicked|travel_document',
  day_arrival: 'application_page|field.clicked|day_arrival',
  month_arrival: 'application_page|field.clicked|month_arrival',
  year_arrival: 'application_page|field.clicked|year_arrival',
  visited_australia_before_no: 'application_page|field.clicked|visited_australia_before_no',
  visited_australia_before_yes: 'application_page|field.clicked|visited_australia_before_yes',
  has_lodged_tfn_abn_application_no: 'application_page|field.clicked|has_lodged_tfn_abn_application_no',
  has_lodged_tfn_abn_application_yes: 'application_page|field.clicked|has_lodged_tfn_abn_application_yes',
  has_tfn_abn_before_no: 'application_page|field.clicked|has_tfn_abn_before_no',
  has_tfn_abn_before_yes: 'application_page|field.clicked|has_tfn_abn_before_yes',
  abn_tfn_input: 'application_page|field.clicked|abn_tfn_input',
  has_lodged_tax_return_no: 'application_page|field.clicked|has_lodged_tax_return_no',
  has_lodged_tax_return_yes: 'application_page|field.clicked|has_lodged_tax_return_yes',
  has_property_in_australia_no: 'application_page|field.clicked|has_property_in_australia_no',
  has_property_in_australia_yes: 'application_page|field.clicked|has_property_in_australia_yes',
  email: 'application_page|field.clicked|email',
  phone_number: 'application_page|field.clicked|phone_number',
  country: 'application_page|field.clicked|country',
  home_auto_address: 'application_page|field.clicked|home_auto_address',
  home_manual_st_no: 'application_page|field.clicked|home_manual_st_no',
  home_manual_st_name: 'application_page|field.clicked|home_manual_st_name',
  home_manual_st_type: 'application_page|field.clicked|home_manual_st_type',
  home_manual_st_city: 'application_page|field.clicked|home_manual_city',
  home_manual_st_state: 'application_page|field.clicked|home_manual_state',
  home_manual_st_post: 'application_page|field.clicked|home_manual_post',
  terms_agree: 'application_page|element.clicked|terms_agree',
  subscription_agree: 'application_page|element.clicked|subscription_agree',
  continue_application: 'application_page|button.clicked|continue_application',
  icon_mastercard: 'payment|button.clicked|icon_mastercard',
  icon_visa: 'payment|button.clicked|icon_visa',
  icon_amex: 'payment|button.clicked|icon_amex',
  icon_paypal: 'payment|button.clicked|icon_paypal',
  icon_bpay: 'payment|button.clicked|icon_bpay',
  icon_bank: 'payment|button.clicked|icon_bank',
  cc_given_name: 'payment|field.clicked|cc_given_name',
  cc_last_name: 'payment|field.clicked|cc_last_name',
  cc_name_title: 'payment|field.clicked|cc_name_title',
  card_number: 'payment|field.clicked|card_number',
  cc_postcode: 'payment|field.clicked|cc_postcode',
  exp_date: 'payment|field.clicked|exp_date',
  security_code: 'payment|field.clicked|security_code',
  email_receipt: 'payment|field.clicked|email_receipt',
  complete_payment: 'payment|button.clicked|complete_payment',
  complete_payment_pending_paypal: 'payment|button.clicked|complete_payment_pending_paypal',
  manual_country: 'payment|field.clicked|manual_country',
  manual_st_name: 'payment|field.clicked|manual_st_name',
  manual_st_city: 'payment|field.clicked|manual_city',
  manual_st_state: 'payment|field.clicked|manual_state',
  manual_st_post: 'payment|field.clicked|manual_post',
  manual_st_country: 'payment|field.clicked|manual_country',
  google_translate: 'homepage|button.clicked|google_translate',
  get_my_tfn: 'homepage|button.clicked|get_my_tfn',
  ato_portal: 'homepage|button.clicked|ato_portal',
  payment_edit_bn: 'payment|button.clicked|payment_edit_bn',
  payment_edit_dn: 'payment|button.clicked|payment_edit_dn',
  payment_edit_gst: 'payment|button.clicked|payment_edit_gst',
  payment_edit_website: 'payment|button.clicked|payment_edit_website',
  payment_edit_email: 'payment|button.clicked|payment_edit_email',
  payment_edit_business_card: 'payment|button.clicked|payment_edit_business_card',
  payment_edit_google_listing: 'payment|button.clicked|payment_edit_google_listing',
  payment_add_bn: 'payment|button.clicked|payment_add_bn',
  payment_add_dn: 'payment|button.clicked|payment_add_dn',
  payment_add_gst: 'payment|button.clicked|payment_add_gst',
  payment_add_website: 'payment|button.clicked|payment_add_website',
  payment_add_email: 'payment|button.clicked|payment_add_email',
  payment_add_business_card: 'payment|button.clicked|payment_add_business_card',
  payment_add_google_listing: 'payment|button.clicked|payment_add_google_listing',
  back_bn: 'payment|button.clicked|back_bn',
  back_dn: 'payment|button.clicked|back_dn',
  back_gst: 'payment|button.clicked|back_gst',
  back_website: 'payment|button.clicked|back_website',
  back_email: 'payment|button.clicked|back_email',
  back_google_listing: 'payment|button.clicked|back_google_listing',
  back_business_card: 'payment|button.clicked|back_business_card',
  payment_remove_bn: 'payment|button.clicked|payment_remove_bn',
  payment_remove_dn: 'payment|button.clicked|payment_remove_dn',
  payment_remove_gst: 'payment|button.clicked|payment_remove_gst',
  payment_remove_website: 'payment|button.clicked|payment_remove_website',
  payment_remove_email: 'payment|button.clicked|payment_remove_email',
  payment_remove_google_listing: 'payment|button.clicked|payment_remove_google_listing',
  payment_remove_business_card: 'payment|button.clicked|payment_remove_business_card',
  popup_close_bn: 'payment|button.clicked|popup_close_bn',
  popup_close_dn: 'payment|button.clicked|popup_close_dn',
  popup_close_gst: 'payment|button.clicked|popup_close_gst',
  popup_close_website: 'payment|button.clicked|popup_close_website',
  popup_close_email: 'payment|button.clicked|popup_close_email',
  popup_close_google_listing: 'payment|button.clicked|popup_close_google_listing',
  popup_close_business_card: 'payment|button.clicked|popup_close_business_card',
};
