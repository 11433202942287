import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { environment } from '@env/environment';
import { FrontEndLogger } from '@mng-reusable/logger/fe-logger/front-end-logger.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingV3Service {
  timeOnPageTimeout = [];
  eventCategory: string;
  gtagRetry = 0;

  constructor(
    @Inject(DOCUMENT) private _document,
    private _feLogger: FrontEndLogger,
  ) {
  }

  initializeTrackingEvents(category: string = ''): void {
    this.gtagIdentify();
    this.setEventCategory(category);
  }

  sendEventsDataByUniqueId(action: string, label: string): void {
    const trackerData = {
      type: 'Frontend.Tracker',
      eventCategory: this.eventCategory,
      eventLabel: label,
    };

    if (this.eventCategory === '' || typeof this.eventCategory === 'undefined') {
      return;
    }

    if (typeof window['gtag'] !== 'undefined') {
      window['gtag']('event', action, {
        event_category: this.eventCategory,
        event_label: label,
        'appVersion': environment.version,
      });
      this.sendDataToLogger(action, label, trackerData);

      return;
    }
  }

  gtagPageLoad(currentUrl: string = '/', category: string = ''): void {
    this.checkIfGtagLoaded().then(() => {
      this.startUserTiming(currentUrl);
    }).catch(() => {
      // tslint:disable-next-line:no-console
      console.log('gtag.loading.error');
    });

    const gtagCall = setInterval(() => {
      if (typeof window['gtag'] !== 'undefined') {
        clearInterval(gtagCall);
        window['gtag']('event', 'page_view', {
          'appVersion': environment.version,
        });
        window['gtag']('event', 'pageload', {
          'event_category': category,
          'appVersion': environment.version,
          'non_interaction': true,
        });
      }
    }, 500);
  }

  private checkIfGtagLoaded(): Promise<any> {
    return new Promise((resolve, reject) => {
      const isLoaded = () => {
        if (this.gtagRetry > 12) {
          reject();
        }

        if (typeof window['gtag'] === 'undefined') {
          setTimeout(isLoaded, 200);
          this.gtagRetry++;

          return;
        }
        resolve(true);
      };

      isLoaded();
    });
  }

  private startUserTiming(currentUrl): void {
    this.resetTimeOnPage();
    const userTimeSpent = (action, label) => {
      window['gtag']('event', action, {
        'event_category': 'TimeOnPage2',
        'event_label': label,
        'appVersion': environment.version,
        'non_interaction': true,
        'page_path': currentUrl,
      });
    };
    const timeSpentInterval = [
      0,
      30,
      60,
      120,
      240,
      360,
      480,
      600,
      1200,
      1800,
      2400,
      3000,
      3600,
    ];

    timeSpentInterval.forEach((seconds, index) => {
      this.timeOnPageTimeout[seconds] = setTimeout(() => {
        const isLastIndex = timeSpentInterval.length === index + 1;
        const isFirstIndex = index === 0;
        const secondStart = isFirstIndex ? 0 : seconds + 1;

        let eventMessage = `${secondStart}+ seconds`;

        if (!isLastIndex) {
          eventMessage = `${secondStart}-${timeSpentInterval[index + 1]} seconds`;
        }

        userTimeSpent(`${index}`, eventMessage);
      }, (seconds + 1) * 1000);
    });
  }

  private resetTimeOnPage(): void {
    this.timeOnPageTimeout.forEach(clearTimeout);
    this.timeOnPageTimeout = [];
  }

  private sendDataToLogger(eventActionType: string, label: string, trackerData: any = ''): void {
    const pageId = this._document.querySelector('body').getAttribute('page-id') || '';
    const eventName = `${eventActionType}`;

    this._feLogger.logFeTracker(eventName, pageId, trackerData);
  }

  private bindGAEvents(category: string): void {
    setTimeout(() => {
      const trackElList = this._document.querySelectorAll('[data-unique-id]:not([tracking])') as HTMLElement[];
      this.setEventCategory(category);

      for (const trackEl of trackElList) {
        const uniqueId = trackEl.getAttribute('data-unique-id');
        const eventActionType = this.getEventActionType(trackEl);
        const eventAction = `${eventActionType}.clicked`;

        trackEl.addEventListener('click', ($event) => {
          $event.stopImmediatePropagation();
          if (uniqueId) {
            this.sendEventsDataByUniqueId(eventAction, uniqueId);
            this.sendDataToLogger(eventActionType, uniqueId);

            return;
          }
        });
        trackEl.setAttribute('tracking', 'true');
      }
    }, 10);
  }

  private setEventCategory(category: string): void {
    if (!category) {
      // todo use angular router
      let currentUrl = window.location.pathname.split('/')[1] || 'homepage';

      if (window.location.pathname.indexOf('/checkout') > - 1) {
        currentUrl = 'payment';
      }

      if (window.location.pathname.indexOf('/register/essentials') > - 1) {
        currentUrl = 'essentials';
      }

      currentUrl.replace(/^\//, '');
      this.eventCategory = currentUrl;

      return;
    }

    this.eventCategory = category;
  }

  private getEventActionType(element: HTMLElement): string {
    if (element.tagName === 'BUTTON' || element.tagName === 'A') {
      return 'button';
    }

    return 'field';
  }

  private gtagIdentify(): void {
    const gtagCall = setInterval(() => {
      if (typeof window['gtag'] !== 'undefined') {
        clearInterval(gtagCall);

        window['gtag']('set', {
          'user_id': uuid(),
          'appVersion': environment.version,
          'send_page_view': true,
        });
      }
    }, 500);
  }
}
