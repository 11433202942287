import { Directive, ElementRef, HostListener, Input, OnInit, Optional } from '@angular/core';
import { FormControlDirective, FormControlName, NgModel } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { TrackingV3Service } from '@mng-reusable/tracking/services/tracking-v3.service';

@Directive({
  selector: '[dirMngDefaultTracker]',
})
export class DefaultTrackerDirective implements OnInit {
  @Input() dirMngDefaultTracker: string;
  @Input() defaultTrackerNgModel: NgModel;
  @Input() defaultTrackerFormControl: FormControlName;
  @Input() ignoreFieldInvalid = false;
  @Input() elementInvalidClick = false;

  eventAction = {
    buttonClicked: 'button.clicked',
    elementInvalid: 'element.invalid',
    fieldClicked: 'field.clicked',
    linkClicked: 'link.clicked',
    fieldInput: 'field.input',
    buttonHover: 'button.hover',
  };

  constructor(
    private _elementRef: ElementRef,
    private _trackingV3Service: TrackingV3Service,
    @Optional() private _ngModel: NgModel,
    @Optional() private _formControlName: FormControlName,
    @Optional() private _formControl: FormControlDirective,
    @Optional() private _matSelect: MatSelect,
  ) {
  }

  ngOnInit(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;

    elem.setAttribute('data-unique-id', this.dirMngDefaultTracker);

    this._trackingV3Service.initializeTrackingEvents();
  }

  @HostListener('openedChange', ['$event'])
  onOpenPanel(isOpen: boolean): void {
    if (isOpen) {
      this.sendClickEvent();
    }
  }

  @HostListener('blur')
  onBlurFormControl(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    elem.removeAttribute('data-input-tracked');
    let elemFormControl = this._formControlName || this._formControl as (FormControlDirective|NgModel);

    if ((!elemFormControl && !this.defaultTrackerFormControl) && (!this._ngModel && !this.defaultTrackerNgModel)) {
      return;
    }

    if (this.defaultTrackerFormControl) {
      elemFormControl = this.defaultTrackerFormControl;
    }
    if (!elemFormControl) {
        // check for ng model
        elemFormControl = this._ngModel;
      if (this.defaultTrackerNgModel) {
        elemFormControl = this.defaultTrackerNgModel;
      }
    }
    const dataUniqueId = elem.getAttribute('data-unique-id');

    if (elemFormControl.invalid && elemFormControl.touched && !this.ignoreFieldInvalid) {
      this._trackingV3Service.sendEventsDataByUniqueId(this.eventAction.elementInvalid, dataUniqueId);
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this._matSelect) {
      return;
    }

    this.sendClickEvent();

    if (this.elementInvalidClick) {
      setTimeout(() => {
        this.onBlurFormControl();
      }, 10);
    }
  }

  @HostListener('input')
  onInput(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    const inputType = elem.getAttribute('type');
    const isNotTextElem = inputType === null || inputType === 'checkbox' || inputType === 'radio';

    if (isNotTextElem || elem.getAttribute('data-input-tracked')) {
      return;
    }

    elem.setAttribute('data-input-tracked', 'true');
    const dataUniqueId = elem.getAttribute('data-unique-id');

    this._trackingV3Service.sendEventsDataByUniqueId(this.eventAction.fieldInput, dataUniqueId);
  }

  private sendClickEvent(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    const eventAction = this.getEventAction(elem);
    const dataUniqueId = elem.getAttribute('data-unique-id');

    this._trackingV3Service.sendEventsDataByUniqueId(eventAction, dataUniqueId);
  }

  private getEventAction(element: HTMLElement): string {
    if (element.tagName === 'BUTTON' || element.tagName === 'A' || element.getAttribute('type') === 'radio' || element.getAttribute('type-2') === 'hover-button')  {
      return this.eventAction.buttonClicked;
    }

    if (element.tagName === 'SPAN' ) {
      return this.eventAction.linkClicked;
    }

    return this.eventAction.fieldClicked;
  }
  @HostListener('change')
  onChange(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    if (elem.getAttribute('type') === 'checkbox') {
      if (elem.getAttribute('class').includes('ng-valid')) {
        const dataUniqueId = elem.getAttribute('data-unique-id');
        this._trackingV3Service.sendEventsDataByUniqueId(this.eventAction.elementInvalid, dataUniqueId);
      }
    }
  }
  @HostListener('mouseover')
  onMouseOver(): void {
    const elem = this._elementRef.nativeElement as HTMLElement;
    if (elem.getAttribute('type') === 'hover'){
      elem.setAttribute('data-hover-tracked', 'true');
      const dataUniqueId = elem.getAttribute('data-unique-id');
      this._trackingV3Service.sendEventsDataByUniqueId(this.eventAction.buttonHover, dataUniqueId);
    }
  }
}
