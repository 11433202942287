import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { TrackingEventsData } from '@mng-reusable/tracking/classes/tracking-events-data';
import { TfnTracking } from '@mng-reusable/tracking/enums/tfn-tracking.enum';
import { TrmTracking } from '@mng-reusable/tracking/enums/trm-tracking.enum';
import { TRACKING_EVENT_DATA_ENUM } from '@mng-reusable/tracking/enums/tracking-event-data.enum';
import { TrackEventService } from '@mng-reusable/tracking/services/track-event.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingV2Service {

  constructor(
    @Inject(DOCUMENT) private _document,
    private _trackEventService: TrackEventService,
  ) {
  }

  /**
   * Split dataTrack string by `|`
   * Parse Tracking Events Data
   */
  public static getEventsData(
    dataTrack: string,
    category: string = '',
    uuid: string = '',
  ): TrackingEventsData {
    const data = dataTrack.split('|');

    return new TrackingEventsData(
      data[0],
      data[1] || category,
      data[2] || undefined,
      undefined,
      uuid,
    );
  }

  /**
   * check if event is enabled
   */
  public static checkIsEventEnabled(eventData: any) {
    try {
      let action = eventData.action;
      const eventCategory = eventData.category.indexOf('custom') !== -1 ? 'custom' : eventData.category;

      if (eventData.label) {
        action = `${action}_${eventData.label}`;
      }

      return TRACKING_EVENT_DATA_ENUM[eventCategory].indexOf(action) !== -1;
    } catch (e) {
      return false;
    }
  }

  /**
   * Bind click listeners for elements with data-track attributes
   */
  bindGAEvents(
    category: string = '',
    userId: string = '',
    uuid: string = '',
  ): void {
    setTimeout(() => {
      const trackElList = this._document.querySelectorAll('[data-track]:not([tracking])') as HTMLElement[];
      const trackElInputList = this._document.querySelectorAll('[data-track-input]:not([input-tracking])') as HTMLElement[];
      const trackElInvalidList = this._document.querySelectorAll('[data-track-invalid]:not([invalid-tracking])') as HTMLElement[];
      const trackElHoverList = this._document.querySelectorAll('[data-track-hover]:not([hover-tracking])') as HTMLElement[];

      for (const trackEl of trackElHoverList) {
        trackEl.addEventListener('mouseover', ($event) => {
          // $event.stopImmediatePropagation();
          const uniqueId = trackEl.getAttribute('data-unique-id');
          const dataTrack = trackEl.getAttribute('data-track-hover');
          if (uniqueId && dataTrack) {
            this.sendEventsDataByUniqueId(uniqueId);

            return;
          }
          this.sendEventsData(dataTrack, category, uuid);
        });

        trackEl.setAttribute('hover-tracking', 'true');
      }

      for (const trackEl of trackElList) {
        trackEl.addEventListener('click', ($event) => {
          // $event.stopImmediatePropagation();

          const uniqueId = trackEl.getAttribute('data-unique-id');
          const dataTrack = trackEl.getAttribute('data-track');
          if (uniqueId && dataTrack) {
            this.sendEventsDataByUniqueId(uniqueId);

            return;
          }
          this.sendEventsData(dataTrack, category, uuid);
        });

        trackEl.setAttribute('tracking', 'true');
      }

      for (const trackElInput of trackElInputList) {
        trackElInput.addEventListener('input', ($event) => {
          // $event.stopImmediatePropagation();

          const uniqueId = trackElInput.getAttribute('data-unique-id');
          const dataTrack = trackElInput.getAttribute('data-track-input');
          const inputTracked = trackElInput.getAttribute('data-input-tracked');

          if (inputTracked) {
            return;
          }

          if (dataTrack && uniqueId) {
            trackElInput.setAttribute('data-input-tracked', 'true');
            this.sendEventsData(dataTrack, category, uuid);
          }
        });

        trackElInput.addEventListener('blur', () => {
          trackElInput.removeAttribute('data-input-tracked');
        });

        trackElInput.setAttribute('input-tracking', 'true');
      }

      for (const trackElInvalid of trackElInvalidList) {
        trackElInvalid.addEventListener('blur', ($event) => {
          let track = false;

          if (trackElInvalid.closest('p-autocomplete')!=null) {
            if(trackElInvalid.closest('p-autocomplete').classList.contains('ng-invalid')) {
              track = true;
            }
          }

          if (trackElInvalid.classList.contains('ng-invalid')) {
            track = true;
          }

          if(track) {
            // $event.stopImmediatePropagation();

            const dataTrack = trackElInvalid.getAttribute('data-track-invalid');
            this.sendEventsData(dataTrack, category, uuid);
          }

        });
        trackElInvalid.setAttribute('invalid-tracking', 'true');
      }
    }, 10);
  }

  /**
   * Send the event data to segment.io and log it
   */
  public sendEventsData(
    dataTrack: string,
    category: string = '',
    uuid: string = '',
  ): void {
    const eventData = TrackingV2Service.getEventsData(dataTrack, category, uuid);
    if (TrackingV2Service.checkIsEventEnabled(eventData)) {
      this._trackEventService.send(eventData);

      return;
    }
  }

  /**
   * Send the event data to segment.io and log it
   */
  public sendEventsDataByUniqueId(
    uniqueId: string,
    uuid: string = '',
    invalid: boolean = false,
  ): void {
    let dataTrack = TfnTracking[uniqueId] || false;
    
    if(!dataTrack) dataTrack = TrmTracking[uniqueId] || false;

    if(!dataTrack) return;

    const data = dataTrack.split('|');
    if (invalid) {
      data[1] = 'element.invalid';
      dataTrack = data.join('|');
    }

    const eventData = TrackingV2Service.getEventsData(dataTrack, '', uuid);
    this._trackEventService.send(eventData);

    return;
  }

  processTrackEvent(personReference, pageId) {
    this._trackEventService.gtagIdentify(personReference);
    this.bindGAEvents('', personReference, pageId);
  }
}
