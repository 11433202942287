import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { ApiRequestLogInterface } from '@mng-reusable/core/interfaces/api-request-log-interface';
import { ApiResponseLogInterface } from '@mng-reusable/core/interfaces/api-response-log-interface';
import { ErrorLogInterface } from '@mng-reusable/core/interfaces/error-log-interface';
import { InfoLogInterface } from '@mng-reusable/core/interfaces/info-log-interface';
import { TrackerLogInterface } from '@mng-reusable/core/interfaces/tracker-log-interface';
import { UserInfoInterface } from '@mng-reusable/core/interfaces/user-info-interface';
import { ValidationLogInterface } from '@mng-reusable/core/interfaces/validation-log-interface';
import { SITE } from '@mng-reusable/core/tokens/site';
import { LogTypeEnum } from '@mng-reusable/logger/enums/log-type.enum';

@Injectable({
  providedIn: 'root',
})
export class FrontEndLogger {
  LOG_TYPE_ENUM = LogTypeEnum;

  constructor(
    @Inject(SITE) private _site: string,
    private _http: HttpClient,
  ) {
  }

  log(event: string, pageId: string): void {
    const infoLog = this.getInfoLog(pageId, event);

    this.callFeLoggerEndpoint(infoLog, this.LOG_TYPE_ENUM.info);
  }

  validationLog(pageId: string, fieldName: string, fieldKey: string, eventName: string): void {
    const validationLog = this.getValidationLog(pageId, fieldName, fieldKey, eventName);

    this.callFeLoggerEndpoint(validationLog, this.LOG_TYPE_ENUM.validation);
  }

  logFeError(event: string, pageId: string, error: any, endpoint: string): void {
    const infoLog = this.getInfoLog(pageId, event);
    const errorLog: ErrorLogInterface = {
      message: error.message || error,
      fileName: error.fileName || '',
      lineNumber: error.lineNumber || '',
      trace: error.trace || error.stack || '',
      url: endpoint || '',
    };

    this.callFeLoggerEndpoint(Object.assign({}, infoLog, errorLog), this.LOG_TYPE_ENUM.error);
  }

  logFeTracker(event: string, pageId: string, tracker: any = ''): void {
    const trackerLog = this.getTrackerLog(pageId, event, tracker);
    this.callFeLoggerEndpoint(trackerLog, this.LOG_TYPE_ENUM.tracker);
  }

  logFeApiRequest(endpoint: string, payload?: any, headers?: any): void {
    const requestData: ApiRequestLogInterface = {
      type: this.LOG_TYPE_ENUM.request,
      endpoint: endpoint,
      payload: payload,
      headers: headers,
    };

    this.apiFeLogger(requestData);
  }

  logFeApiResponseSuccess(endpoint: string, payload: any): void {
    const responseData: ApiResponseLogInterface = {
      type: this.LOG_TYPE_ENUM.responseSuccess,
      endpoint: endpoint,
      result: payload,
    };

    this.apiFeLogger(responseData);
  }

  logFeApiResponseError(endpoint: string, payload: any): void {
    const responseData: ApiResponseLogInterface = {
      type: this.LOG_TYPE_ENUM.responseError,
      endpoint: endpoint,
      result: payload,
    };

    this.apiFeLogger(responseData);
  }

  logFeApiResponseFailed(endpoint: string, payload: any): void {
    const responseData: ApiResponseLogInterface = {
      type: this.LOG_TYPE_ENUM.responseFailed,
      endpoint: endpoint,
      result: payload,
    };

    this.apiFeLogger(responseData);
  }

  private static getUserInfo(): UserInfoInterface {
    const nav = window.navigator;
    const screen = window.screen;

    return {
      screenWidth: screen.width,
      screenHeight: screen.height,
      userAgent: nav.userAgent,
      platform: nav.platform,
    };
  }

  private static getIdentity(): string {
    const analytics = (<any>window).analytics;
    if (typeof analytics === 'undefined' || typeof analytics.user === 'undefined') {
      return '';
    }

    if (!(analytics.user().id())) {
      return analytics.user().anonymousId();
    }

    return analytics.user().id();
  }

  private getInfoLog(pageId: string, eventName: string): InfoLogInterface {
    return {
      identity: FrontEndLogger.getIdentity(),
      pageId: pageId,
      eventName: eventName,
      url: window.location.href,
      referrer: this._site || '',
      pageInfo: FrontEndLogger.getUserInfo(),
    };
  }

  private getTrackerLog(pageId: string, eventName: string, trackerData: any = ''): TrackerLogInterface {
    return {
      identity: FrontEndLogger.getIdentity(),
      pageId: pageId,
      eventAction: eventName,
      eventCategory: trackerData.eventCategory,
      eventLabel: trackerData.eventLabel,
      url: window.location.href,
      referrer: this._site || '',
      pageInfo: FrontEndLogger.getUserInfo(),
    };
  }

  private getValidationLog(pageId: string, fieldName: string, fieldKey: string, eventName: string): ValidationLogInterface {
    return {
      identity: FrontEndLogger.getIdentity(),
      fieldName: fieldName,
      fieldKey: fieldKey,
      pageId: pageId,
      eventName: eventName,
      url: window.location.href,
      referrer: this._site || '',
      pageInfo: FrontEndLogger.getUserInfo(),
    };
  }

  private callFeLoggerEndpoint(data: InfoLogInterface | ErrorLogInterface
    | ValidationLogInterface | TrackerLogInterface, type: string): void {
    this._http.post(
      `${environment.logger.activeFeLogger}`, JSON.stringify(Object.assign({}, data, { type })), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    ).pipe(
      catchError((e) => {
        // tslint:disable-next-line:no-console
        console.log('fe.logger.failed');

        return of(undefined);
      }),
    ).subscribe();
  }

  private apiFeLogger(data: ApiRequestLogInterface | ApiResponseLogInterface): void {
    this._http.post(
      `${environment.logger.apiFeLogger}`, JSON.stringify(Object.assign({}, data)), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    ).pipe(
      catchError((e) => {
        // tslint:disable-next-line:no-console
        console.log('api.logger.failed');

        return of(undefined);
      }),
    ).subscribe();
  }
}
