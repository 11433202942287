import { Injectable } from '@angular/core';
import { get, isEqual, isNil, mergeWith } from 'lodash';
import { Observable, pipe, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, scan, shareReplay } from 'rxjs/operators';

import { Action } from './action';

const win = window as any;
if (win.__REDUX_DEVTOOLS_EXTENSION__) {
  const reduxDevToolsExtension = win.__REDUX_DEVTOOLS_EXTENSION__ as any;
  win.reduxDevTools = reduxDevToolsExtension.connect();
}

export const reducer = () =>
  scan<any>((state: Observable<any>, action: Action) => {
    let next;
    switch (action.type) {
      case 'SET':
        next = action.payload;
        break;
      case 'UPDATE':
        next = mergeWith(state, action.payload, function (obj: any, src: any) {
          if (!isNil(src)) {
            return src;
          }

          return obj;
        });
        break;
      default:
        next = state;
        break;
    }

    if (win.reduxDevTools) {
      win.reduxDevTools.send(action.type, next);
    }

    return next;
  }, {});

export const select = (path: string) =>
  pipe(
    map(state => get(state, path, undefined)),
    distinctUntilChanged(isEqual),
  );

@Injectable({
  providedIn: 'root',
})
export class Store {
  state: Observable<any>;
  actions: ReplaySubject<Action> = new ReplaySubject<Action>();
  devTools: any;

  constructor() {
    this.state = this.actions.pipe(
      reducer(),
      shareReplay(1),
    );
  }

  select(path: string): Observable<any> {
    return this.state.pipe(select(path));
  }

  dispatch(action: Action): void {
    this.actions.next(action);
  }
}
