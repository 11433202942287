import { TrackingEventsJson } from '@mng-reusable/tracking/interfaces/tracking-events-json';

export class TrackingEventsData {
  constructor(
    readonly category: string,
    readonly action: string,
    readonly label?: string,
    readonly userId?: string,
    readonly uuid?: string,
    readonly email?: string,
    readonly phoneNumber?: number,
    readonly services?: string,
    readonly layoutDashboard?: string,
    readonly breakPoint?: string,
    readonly industry?: string,
  ) {
  }

  /**
   * gets event json for tracking events
   */
  getEventJson(): TrackingEventsJson {
    const data = {
      category: this.category,
      label: this.label,
      userId: this.userId,
      email: this.email,
      phoneNumber: this.phoneNumber,
      services: this.services,
      layoutDashboard: this.layoutDashboard,
      breakPoint: this.breakPoint,
      uuid: this.uuid,
      industry: this.industry,
    };

    if (!this.userId) {
      delete data.userId;
    }

    if (!this.label) {
      delete data.label;
    }

    if (!this.email) {
      delete data.email;
    }

    if (!this.phoneNumber) {
      delete data.phoneNumber;
    }

    if (!this.services) {
      delete data.services;
    }

    if (!this.layoutDashboard) {
      delete data.layoutDashboard;
    }

    if (!this.breakPoint) {
      delete data.breakPoint;
    }

    if (!this.uuid) {
      delete data.uuid;
    }

    if (!this.industry) {
      delete data.industry;
    }

    return data;
  }
}
