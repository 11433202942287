export const TRACKING_EVENT_DATA_ENUM = {
  'header': [
    'link.clicked_learn_more',
    'link.clicked_pricing',
    'link.clicked_contact_us',
    'link.clicked_phone_number'
  ],
  'homepage': [
    'field.clicked_input_tm',
    'field.input_input_tm',
    'button.clicked_check_tm_availability',
    'link.clicked_tm_logo',
    'link.clicked_tm_name',
    'button.clicked_start_now',
  ],
  'footer': [
    'link.clicked_about_us',
    'link.clicked_contact_us',
    'link.clicked_pricing',
    'link.clicked_terms_of_use',
    'link.clicked_privacy_policy',
    'link.clicked_bn_rego',
    'link.clicked_bn_renewal',
    'link.clicked_bn_transfer',
    'link.clicked_comp_rego',
    'link.clicked_abn_registration',
    'link.clicked_tfn_rego',
    'link.clicked_dn',
    'link.clicked_email',
    'link.clicked_website',
    'link.clicked_google_listing',
  ],

  'registration': [
    'link.clicked_abn_inactive',
    'field.clicked_tm_for',
    'field.input_tm_for',
    'element.invalid_tm_for',
    'button.clicked_tm_remove',
    'button.hover_tm_classification',
    'checkbox.clicked_expert_draft',
    'button.hover_tm_usage',
    'button.clicked_privacy_yes',
    'button.clicked_privacy_no',
    'button.clicked_business',
    'button.clicked_individual',
    'link.clicked_abn_acn_not_valid',
    'link.clicked_abn_not_valid',
    'field.clicked_abn_acn',
    'field.input_abn_acn',
    'element.invalid_abn_acn',
    'button.hover_abn_acn_tooltip',
    'button.clicked_abn_acn_tooltip',
    'link.clicked_abn_acn_here',
    'button.clicked_individual_no',
    'button.clicked_individual_yes',
    'field.clicked_given_name',
    'field.input_given_name',
    'element.invalid_given_name',
    'field.clicked_last_name',
    'field.input_last_name',
    'element.invalid_last_name',
    'field.clicked_ind_abn',
    'field.input_ind_abn',
    'element.invalid_ind_abn',
    'button.hover_abn_registration_tooltip',
    'button.clicked_abn_registration_tooltip',
    'link.clicked_abn_here',
    'field.clicked_email_address',
    'element.invalid_email_address',
    'field.clicked_phone_number',
    'element.invalid_phone_number',
    'field.clicked_auto_address',
    'field.input_auto_address',
    'element.invalid_auto_address',
    'link.clicked_auto_to_manual_address',
    'field.clicked_street_no',
    'field.input_street_no',
    'element.invalid_street_no',
    'field.clicked_street_name',
    'field.input_street_name',
    'element.invalid_street_name',
    'field.clicked_city',
    'field.input_city',
    'element.invalid_city',
    'field.clicked_state',
    'element.invalid_state',
    'field.clicked_post',
    'field.input_post',
    'element.invalid_post',
    'link.clicked_manual_to_auto_address',
    'button.clicked_bn_3_years',
    'button.clicked_bn_1_year',
    'button.clicked_bn_no',
    'field.clicked_country_of_birth',
    'field.clicked_state_of_birth',
    'field.clicked_city_of_birth',
    'field.input_city_of_birth',
    'element.invalid_city_of_birth',
    'button.hover_asic_agent_tooltip',
    'button.clicked_asic_agent_tooltip',
    'button.clicked_dn_2_years',
    'button.clicked_dn_new',
    'button.clicked_dn_no',
    'button.hover_domain_name_tooltip',
    'button.clicked_domain_name_tooltip',
    'checkbox.clicked_terms_agree',
    'element.invalid_terms_agree',
    'link.clicked_terms_of_service',
    'button.clicked_continue',
    'field.clicked_dn_different',
    'field.input_dn_different',
    'element.invalid_dn_different',
    'field.clicked_tld',
    'field.clicked_terms',
    'button.clicked_dn_search',
    'button.clicked_dn_clear',
  ],

  'm.abn': [
    'tile.clicked',
  ],
  'm.bnm': [
    'tile.clicked',
  ],
  'm.bank': [
    'tile.clicked',
    'element.clicked_registration.form.applyNow',
    'element.clicked_registration.form.activateABN',
    'element.clicked_registration.form.getABN',
    'element.clicked_registration.form.personalNext',
    'element.clicked_registration.form.personalNextSbola',
    'element.clicked_registration.form.taxResidencyNext',
    'element.clicked_registration.form.businessDetailsNext',
    'element.clicked_registration.form.informationIsAccurate',
    'element.clicked_submitApplicationModal.agreeAbove',
    'element.clicked_submitApplicationModal.agreeEmailHoncho',
    'element.clicked_termsAndCondition.accept',
    'registration.form.submitted_submitApplicationModal.submitApplication',
    'registration.form.submitted_registration.form.confirmationSubmitApplication',
    'registration.form.displayed_registration.form.addAbn',
    'registration.form.displayed_registration.form.openAbusinessAccount',
    'registration.form.displayed_registration.form.personalDetails',
    'registration.form.displayed_registration.form.taxResidencyDetails',
    'registration.form.displayed_registration.form.businessDetails',
    'registration.form.displayed_registration.form.confirmation',
    'success.modal.displayed_modal.submitApplication',
    'success.modal.displayed_modal.termsAndCondition',
    'element.clicked_registration.form.openAbusinessAccountClose',
    'element.clicked_registration.form.title',
    'element.clicked_registration.form.firstName',
    'element.clicked_registration.form.midName',
    'element.clicked_registration.form.lastName',
    'element.clicked_registration.form.iDontHaveMidName',
    'element.clicked_registration.form.gender',
    'element.clicked_registration.form.dateofBirth',
    'element.clicked_registration.form.email',
    'element.clicked_registration.form.preferredContact',
    'element.clicked_registration.form.mobile',
    'element.clicked_registration.form.phoneNumber',
    'element.clicked_registration.form.AddLandline',
    'element.clicked_registration.form.Addmobile',
    'element.clicked_registration.form.homeAutoAddress',
    'element.clicked_registration.form.homeManualAddress',
    'element.clicked_registration.form.homeStreetNo',
    'element.clicked_registration.form.homeStreetName',
    'element.clicked_registration.form.homeCity',
    'element.clicked_registration.form.homeState',
    'element.clicked_registration.form.homePost',
    'element.clicked_registration.form.personalBack',
    'element.clicked_registration.form.taxResidentInAustralia',
    'element.clicked_registration.form.countryOfTaxResidency',
    'element.clicked_registration.form.haveTin',
    'element.clicked_registration.form.TinNumber',
    'element.clicked_registration.form.existingANZcustomer',
    'element.clicked_registration.form.taxResidencyBack',
    'element.clicked_registration.form.businessName',
    'element.clicked_registration.form.entityType',
    'element.clicked_registration.form.abnActiveDate',
    'element.clicked_registration.form.businessAutoAddress',
    'element.clicked_registration.form.businessManualAddress',
    'element.clicked_registration.form.businessStreetNo',
    'element.clicked_registration.form.businessStreetName',
    'element.clicked_registration.form.businessCity',
    'element.clicked_registration.form.businessState',
    'element.clicked_registration.form.businessPost',
    'element.clicked_registration.form.Industry',
    'element.clicked_registration.form.occupation',
    'element.clicked_registration.form.position',
    'element.clicked_registration.form.businessDetailsBack',
    'element.clicked_registration.form.editPersonalDetaiils',
    'element.clicked_registration.form.editBusinessDetaiils',
    'element.clicked_registration.form.editTaxResidency',
  ],
  'm.domain': [
    'tile.clicked',
  ],
  'm.insurance': [
    'tile.clicked',
    'element.clicked_page.getquote',
    'element.clicked_request.form.insurance.getaquote',
    'element.clicked_request.form.title',
    'element.clicked_request.form.firstName',
    'element.clicked_request.form.lastName',
    'element.clicked_request.form.email',
    'element.clicked_request.form.industry',
    'element.clicked_request.form.sub.industry',
    'element.clicked_request.form.insurance.close',
    'request.form.displayed_request.form.insurance',
    'element.clicked_success.form.thankyou.done',
    'element.clicked_success.form.thankyou.back',
    'element.clicked_success.form.thankyou.close',
    'success.form.displayed_success.form.thankyou',
    'element.clicked_registration.form.comingsoon.keepmeintheloop',
    'element.clicked_registration.form.comingsoon.email',
    'element.clicked_registration.form.comingsoon.close',
    'element.clicked_registration.form.comingsoon.back',
    'registration.form.displayed_registration.form.comingsoon',
    'registration.form.displayed_registration.form.getyourquote',
    'element.clicked_registration.form.getyourquote.setupinsurancenow',
    'element.clicked_registration.form.getyourquote.close',
    'element.clicked_registration.form.getyourquote.back',
    'registration.form.displayed_registration.form.yourinsurance',
    'element.clicked_registration.form.yourinsurance.close',
    'element.clicked_registration.form.yourinsurance.manage',
    'element.clicked_request.form.getaquote',
  ],
  'm.trade.mark': [
    'tile.clicked',
  ],
  'trade.mark': [
    'tile.clicked',
  ],
  domain: [
    'tile.clicked',
  ],
  insurance: [
    'tile.clicked',
  ],
  mango: [
    'element.clicked_header.abnLogo',
    'element.clicked_header.about',
    'element.clicked_header.account',
    'element.clicked_header.blog',
    'element.clicked_header.support',
    'element.clicked_header.honchoLogo',
    'element.clicked_header.default.abn',
    'element.clicked_header.default.bank',
    'element.clicked_header.default.bnm',
    'element.clicked_header.default.insurance',
    'element.clicked_header.default.trademarks',
    'element.clicked_header.default.web.address',
    'element.clicked_header.default.website',
    'element.clicked_header.default.gst',
    'element.clicked_header.default.email',
    'element.clicked_header.default.adwords',
    'element.clicked_header.default.invoices',
    'element.clicked_header.default.essentials',
    'element.clicked_header.default.cbd',
    'link.clicked_footer.about',
    'link.clicked_footer.blog',
    'link.clicked_footer.careers',
    'link.clicked_footer.contact',
    'link.clicked_footer.domain',
    'link.clicked_footer.faq',
    'link.clicked_footer.insurance',
    'link.clicked_footer.privacyPolicy',
    'link.clicked_footer.support',
    'link.clicked_footer.terms',
    'link.clicked_footer.tradeMark',
    'mango.loaded',
    'mango.loaded_referrer.ABN',
    'mango.signUp',
  ],
  custom: [
    'tile.clicked',
  ],
  'm.account': [
    'element.clicked_login.dialog.email',
    'element.clicked_login.dialog.password',
    'element.clicked_login.dialog.loginSubmit',
    'element.clicked_login.dialog.createAccount',
    'element.clicked_login.dialog.forgotPassword',
    'element.clicked_signup.dialog.firstName',
    'element.clicked_signup.dialog.lastName',
    'element.clicked_signup.dialog.email',
    'element.clicked_signup.dialog.password',
    'element.clicked_signup.dialog.signUpSubmit',
    'element.clicked_signup.dialog.login',
    'element.clicked_account.dialog.close',
    'element.clicked_account.dialog.logout',
    'element.clicked_account.dialog.switch',
    'element.clicked_magicLink.dialog.back',
    'element.clicked_magicLink.dialog.email',
    'element.clicked_magicLink.dialog.magicLinkSubmit',
    'element.clicked_magicLink.dialog.done',
    'success.modal.displayed_signup.dialog.success',
  ],
  'payment': [
    'button.clicked_eway',
    'button.clicked_complete_payment',
    'button.clicked_email_receipt_to_t',
    'button.clicked_icon_amex',
    'button.clicked_icon_bank',
    'button.clicked_icon_bpay',
    'button.clicked_icon_mastercard',
    'button.clicked_icon_paypal',
    'button.clicked_icon_visa',
    'button.hover_email_receipt_to_t',
    'element.invalid_card_number',
    'element.invalid_cc_given_name',
    'element.invalid_cc_last_name',
    'element.invalid_cc_postcode',
    'element.invalid_city',
    'element.invalid_country',
    'element.invalid_email_receipt',
    'element.invalid_exp_date',
    'element.invalid_postcode',
    'element.invalid_security_code',
    'element.invalid_state',
    'element.invalid_street_no_name',
    'field.clicked_card_number',
    'field.clicked_cc_given_name',
    'field.clicked_cc_last_name',
    'field.clicked_city',
    'field.clicked_country',
    'field.clicked_email_receipt',
    'field.clicked_exp_date',
    'field.clicked_postcode',
    'field.clicked_security_code',
    'field.clicked_state',
    'field.clicked_street_no_name',
    'field.input_card_number',
    'field.input_cc_given_name',
    'field.input_cc_last_name',
    'field.input_cc_postcode',
    'field.input_city',
    'field.input_email_receipt',
    'field.input_exp_date',
    'field.input_postcode',
    'field.input_security_code',
    'field.input_state',
    'field.input_street_no_name',
  ],
  'payment2': [
    'field.clicked_cc_given_name',
    'field.clicked_cc_last_name',
    'field.clicked_card_number',
    'field.clicked_exp_date',
    'field.clicked_security_code',
    'field.clicked_email_receipt',
    'field.input_cc_given_name',
    'field.input_cc_last_name',
    'field.input_card_number',
    'field.input_exp_date',
    'field.input_security_code',
    'field.input_email_receipt',
    'field.input_cc_postcode',
    'button.clicked_icon_mastercard',
    'button.clicked_icon_visa',
    'button.clicked_icon_amex',
    'button.clicked_icon_paypal',
    'button.clicked_icon_bpay',
    'button.clicked_icon_bank',
    'button.clicked_complete_payment',
    'element.invalid_cc_given_name',
    'element.invalid_cc_last_name',
    'element.invalid_card_number',
    'element.invalid_exp_date',
    'element.invalid_security_code',
    'element.invalid_email_receipt',
    'element.invalid_cc_postcode',
  ],
};
