import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

import { TrackingV3Service } from '@mng-reusable/tracking/services/tracking-v3.service';

@Directive({
  selector: '[dirMngInvalidTracker]',
})
export class InvalidTrackerDirective {
  constructor(
    private _elementRef: ElementRef,
    private _ngModel: NgModel,
    private _trackingV3Service: TrackingV3Service,
  ) {
  }

  @HostListener('blur')
  onBlur(): void {
    if (this._ngModel.invalid && this._ngModel.touched) {
      const uniqueId = this._elementRef.nativeElement.getAttribute('data-unique-id');

      this._trackingV3Service.sendEventsDataByUniqueId('element.invalid', uniqueId);
    }
  }
}
