import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DefaultTrackerDirective } from '@mng-reusable/tracking/directives/default-tracker.directive';
import { InvalidTrackerDirective } from '@mng-reusable/tracking/directives/invalid-tracker.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DefaultTrackerDirective,
    InvalidTrackerDirective,
  ],
  exports: [
    DefaultTrackerDirective,
    InvalidTrackerDirective,
  ],
})
export class TrackingDirectivesModule {
}
