import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@env/environment';
import { FrontEndLogger } from '@mng-reusable/logger/fe-logger/front-end-logger.service';

@Injectable()
export class FeLoggerErrorHandler implements ErrorHandler {

  constructor(
    @Inject(DOCUMENT) private _document,
    private _feLogger: FrontEndLogger,
    private _cookieService: CookieService,
  ) {
  }

  handleError(error: any) {
    const page = this._document.querySelector('body');
    const pageId = page.getAttribute('page-id');
    this._feLogger.logFeError('Console Error', pageId, error, '');

    if (!environment.production) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }

    if (!error.message) {
      return;
    }

    if (error.message.indexOf('Loading chunk') !== -1) {
      const pageReloadCount = Number(this._cookieService.get('page-reload-count') || 0) + 1;

      this._cookieService.set('page-reload-count', JSON.stringify(pageReloadCount), 1, '/');

      if (pageReloadCount <= 3) {
        location.reload();

        return;
      }

      setTimeout(() => {
        this._cookieService.set('page-reload-count', '0', 1, '/');
      }, 3000);
    }
  }
}
