import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) {
  }

  public initialize(): void {
    if (environment.googleTagManager && environment.googleTagManager.enabled) {
      const script = this._document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.googleTagManager.tagManagerId}');`;

      const iframe = this._document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.googleTagManager.tagManagerId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.cssText = 'display:none;visibility:hidden;';

      const noscript = this._document.createElement('noscript') as any;
      noscript.type = 'text/javascript';
      noscript.appendChild(iframe);

      this._document.head.appendChild(script);
      this._document.body.insertBefore(noscript, this._document.body.childNodes[0]);
    }
  }
}
