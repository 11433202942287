export enum LogTypeEnum {
  info = 'FrontEnd.Info',
  error = 'FrontEnd.Error',
  validation = 'FrontEnd.Validation',
  tracker = 'FrontEnd.Tracker',
  request = 'request',
  responseSuccess = 'success',
  responseFailed = 'failed',
  responseError = 'error',
}
