import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { SupportedSite } from '@mng-reusable/core/enums/supported-site.enum';
import { OrderDetails } from '@mng-reusable/payment/interfaces/order-details';
import { UnpaidApplicationDetails } from '@mng-reusable/payment/interfaces/unpaid-application-details';
import { TrackingEventsData } from '@mng-reusable/tracking/classes/tracking-events-data';
import { TrackingEventsDataV2 } from '@mng-reusable/tracking/classes/tracking-events-data-v2';
import { TrackingIdentifyData } from '@mng-reusable/tracking/classes/tracking-identify-data';

@Injectable({
  providedIn: 'root',
})
export class TrackEventService {
  timeOnPageTimeout = [];

  /**
   * Track the user identity if logged in
   *
   * userId format <HON123456789>
   */
  public identify(identifyData: TrackingIdentifyData): void {
    if (typeof window['analytics'] === 'undefined') {
      return;
    }
    window['analytics'].identify(identifyData.userId, identifyData.traits);
  }

  public gtagPageLoad(currentUrl: string = '/'): void {
    this.checkIfGtagLoaded().then(() => {
      this.startUserTiming(currentUrl);
    });

    const gtagCall = setInterval(() => {
      if (typeof window['gtag'] !== 'undefined') {
        clearInterval(gtagCall);
        window['gtag']('event', 'page_view', {
          'appVersion': environment.version,
        });
        window['gtag']('event', 'pageload', {
          'appVersion': environment.version,
          'non_interaction': true,
        });
      }
    }, 500);
  }

  public gtagIdentify(personReference: string): void {
    const gtagCall = setInterval(function () {
      if (typeof window['gtag'] !== 'undefined') {
        clearInterval(gtagCall);

        window['gtag']('set', {
          'user_id': personReference,
        });
      }
    }, 500);
  }

  /**
   * Send the parsed tracking event data
   */
  public send(data: (TrackingEventsDataV2 | TrackingEventsData)): void {
    let loadedTracker = window['gtag'] || undefined;

    if (typeof window['customtracking'] !== 'undefined') {
      loadedTracker = window['customtracking'];
    }

    if (typeof loadedTracker !== 'undefined') {
      loadedTracker('event', data.action, {
        event_category: data.category,
        event_label: data.label,
        'appVersion': environment.version,
      });

      return;
    }

    if (typeof window['analytics'] === 'undefined') {
      return;
    }
    const payload = data.getEventJson();
    const splitPayload = this.getSplitTestPayload();
    window['analytics'].track(data.action, { ...payload, ...splitPayload });
  }

  public successPaymentEvents(): void {
    let loadedTracker = window['gtag'] || undefined;

    if (typeof window['customtracking'] !== 'undefined') {
      loadedTracker = window['customtracking'];
    }

    if (typeof loadedTracker !== 'undefined') {
      loadedTracker('event', 'payment.successful', {
        event_category: 'payment',
        event_label: 'success.payment.modal',
        'appVersion': environment.version,
      });
    }

    // SEGMENT TRACKING/GOOGLE ANALYTICS
    if (typeof window['analytics'] !== 'undefined') {
      const payload = { category: 'payment', label: 'success.payment.modal' };
      const splitPayload = this.getSplitTestPayload();
      window['analytics'].track('payment.successful', { ...payload, ...splitPayload });
    }

    // GOOGLE ADWORDS
    if (typeof window['gtag'] !== 'undefined' && environment.googleAdwords.enabled) {
      window['gtag']('event', 'conversion', {
        'send_to': `${environment.googleAdwords.token}/${environment.googleAdwords.label}`,
        'transaction_id': '',
        'value': '1',
      });
    }

    // BING
    if (typeof window['uetq'] !== 'undefined') {
      window['uetq'].push('event', 'payment.successful', {
        event_category: 'payment',
        event_label: 'success.payment.modal',
        event_value: 1,
        revenue_value: '1',
      });
    }
  }

  public sendUserDataGtag(details, site): void {
    if (site === SupportedSite.abnStandalone) {
      // GOOGLE ADWORDS
      if (typeof window['gtag'] !== 'undefined' && environment.googleAdwords.enabled) {
        window['gtag']('set', 'user_data', {
          'email': details.emailAddress,
          'phone_number': details.contact,
          'address': {
            'first_name': details.firstName,
            'last_name': details.lastName,
            'street': details.billingAddress.streetName,
            'city': details.billingAddress.city,
            'postal_code': details.billingAddress.postcode,
            'country': details.billingAddress.countryCode,
          },
        });
      }
    }
  }

  public pendingPaymentEvents(): any {
    let loadedTracker = window['gtag'] || undefined;

    if (typeof window['customtracking'] !== 'undefined') {
      loadedTracker = window['customtracking'];
    }

    if (typeof loadedTracker !== 'undefined') {
      loadedTracker('event', 'payment.pending', {
        event_category: 'payment2',
        event_label: 'Pending Payment Modal',
        'appVersion': environment.version,
      });
    }
    // SEGMENT TRACKING/GOOGLE ANALYTICS
    if (typeof window['analytics'] !== 'undefined') {
      const payload = { category: 'payment', label: 'Pending Payment Modal' };
      const splitPayload = this.getSplitTestPayload();
      window['analytics'].track('payment.pending', { ...payload, ...splitPayload });
    }

    // GOOGLE ADWORDS
    if (typeof window['gtag'] !== 'undefined' && environment.googleAdwords.enabled) {
      window['gtag']('event', 'conversion', {
        'send_to': `${environment.googleAdwords.token}/${environment.googleAdwords.label}`,
        'transaction_id': '',
        'value': '1',
      });
    }

    // BING
    if (typeof window['uetq'] !== 'undefined') {
      window['uetq'].push('event', 'payment.successful', {
        event_category: 'payment',
        event_label: 'success.payment.modal',
        event_value: 1,
        revenue_value: '1',
      });
    }
  }

  public failedPaymentEvents(): any {
    let loadedTracker = window['gtag'] || undefined;

    if (typeof window['customtracking'] !== 'undefined') {
      loadedTracker = window['customtracking'];
    }

    if (typeof loadedTracker !== 'undefined') {
      loadedTracker('event', 'payment.failed', {
        event_category: 'payment2',
        event_label: 'Failed Payment Modal',
        'appVersion': environment.version,
      });
    }
    // SEGMENT TRACKING/GOOGLE ANALYTICS
    if (typeof window['analytics'] !== 'undefined') {
      const payload = { category: 'payment', label: 'Failed Payment Modal' };
      const splitPayload = this.getSplitTestPayload();
      window['analytics'].track('payment.failed', { ...payload, ...splitPayload });
    }
  }

  public getSplitTestPayload(): object {
    if (!environment.splitExperiment.enabled) {
      return {};
    }

    return {
      experiment_id: environment.splitExperiment.experimentId,
      variation_id: environment.splitExperiment.variationId,
    };
  }

  private getItemsFromUnpaid(
    unpaidDetails: UnpaidApplicationDetails,
  ): {id: string, name: string, quantity: string, price: string}[] {
    const items = [];

    unpaidDetails.orderDetails.forEach((order: OrderDetails) => {
      items.push({
        id: order.serviceId || '',
        name: order.title || '',
        quantity: 1,
        price: order.price || '',
      });
    });

    return items;
  }

  private resetTimeOnPage(): void {
    this.timeOnPageTimeout.forEach(clearTimeout);
    this.timeOnPageTimeout = [];
  }

  private checkIfGtagLoaded(): Promise<any> {
    let count = 0;

    return new Promise((resolve, reject) => {
      const isLoaded = () => {
        if (count > 100) { // stop after 10 seconds
          reject();
        }

        if (typeof window['gtag'] === 'undefined') {
          setTimeout(isLoaded, 100);
          count++;

          return;
        }
        resolve(true);
      };

      isLoaded();
    });
  }

  private startUserTiming(currentUrl): void {
    this.resetTimeOnPage();
    const userTimeSpent = (action, label) => {
      window['gtag']('event', action, {
        'event_category': 'TimeOnPage2',
        'event_label': label,
        'appVersion': environment.version,
        'non_interaction': true,
        'page_path': currentUrl,
      });
    };
    const timeSpentInterval = [
      0,
      30,
      60,
      120,
      240,
      360,
      480,
      600,
      1200,
      1800,
      2400,
      3000,
      3600,
    ];

    timeSpentInterval.forEach((seconds, index) => {
      this.timeOnPageTimeout[seconds] = setTimeout(() => {
        const isLastIndex = timeSpentInterval.length === index + 1;
        const isFirstIndex = index === 0;
        const secondStart = isFirstIndex ? 0 : seconds + 1;

        let eventMessage = `${secondStart}+ seconds`;

        if (!isLastIndex) {
          eventMessage = `${secondStart}-${timeSpentInterval[index + 1]} seconds`;
        }

        userTimeSpent(`${index}`, eventMessage);
      }, (seconds + 1) * 1000);
    });
  }
}
